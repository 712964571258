import revive_payload_client_n79tviL7Bp from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.1_db0@0.2.1_eslint@9.20.0_jiti@2.4.2__ior_ti2arcgswqk6mbp7ab4equxubu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_zEI1c889kG from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.1_db0@0.2.1_eslint@9.20.0_jiti@2.4.2__ior_ti2arcgswqk6mbp7ab4equxubu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_XyqMwg03NS from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.1_db0@0.2.1_eslint@9.20.0_jiti@2.4.2__ior_ti2arcgswqk6mbp7ab4equxubu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_UTRj1zX4xA from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_rollup@4.30.1_vite@6.0.11_@types+node@22.13.1_jiti@2.4._6dud4no3tp37p3hm3kihp7blne/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_FNLySCN7Bz from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.1_db0@0.2.1_eslint@9.20.0_jiti@2.4.2__ior_ti2arcgswqk6mbp7ab4equxubu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_vgUqIegCee from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.1_db0@0.2.1_eslint@9.20.0_jiti@2.4.2__ior_ti2arcgswqk6mbp7ab4equxubu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_9AR0Qf0qCe from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.1_db0@0.2.1_eslint@9.20.0_jiti@2.4.2__ior_ti2arcgswqk6mbp7ab4equxubu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_FkbJqd1pU6 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.1_db0@0.2.1_eslint@9.20.0_jiti@2.4.2__ior_ti2arcgswqk6mbp7ab4equxubu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/docs/.nuxt/components.plugin.mjs";
import prefetch_client_jIJuoFWV1Z from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.1_db0@0.2.1_eslint@9.20.0_jiti@2.4.2__ior_ti2arcgswqk6mbp7ab4equxubu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_PmNkiK7xxA from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.30.1_vite@6.0.11_@types+node@22.13.1_jiti@2.4.2_sas_7ey5t4qtga3fw6ellj72dplp2u/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import documentDriven_YCZdWOJXlj from "/vercel/path0/node_modules/.pnpm/@nuxt+content@2.13.4_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_nuxt@3.15.4_@parcel+watcher@2.5.0_5m36tyhfgwv7mc2zvoa7wgi3oq/node_modules/@nuxt/content/dist/runtime/legacy/plugins/documentDriven.js";
import plugin_client_qJsI3aBQAJ from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.30.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import unocss_MzCDxu9LMj from "/vercel/path0/docs/.nuxt/unocss.mjs";
import theme_client_8QYLtQWjlY from "/vercel/path0/packages/nuxt/src/runtime/plugins/theme.client.ts";
import analytics_client_mtqVw3YRXk from "/vercel/path0/docs/plugins/analytics.client.ts";
export default [
  revive_payload_client_n79tviL7Bp,
  unhead_zEI1c889kG,
  router_XyqMwg03NS,
  _0_siteConfig_UTRj1zX4xA,
  payload_client_FNLySCN7Bz,
  navigation_repaint_client_vgUqIegCee,
  check_outdated_build_client_9AR0Qf0qCe,
  chunk_reload_client_FkbJqd1pU6,
  components_plugin_KR1HBZs4kY,
  prefetch_client_jIJuoFWV1Z,
  plugin_PmNkiK7xxA,
  documentDriven_YCZdWOJXlj,
  plugin_client_qJsI3aBQAJ,
  unocss_MzCDxu9LMj,
  theme_client_8QYLtQWjlY,
  analytics_client_mtqVw3YRXk
]