import routerOptions0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.13.1_db0@0.2.1_eslint@9.20.0_jiti@2.4.2__ior_ti2arcgswqk6mbp7ab4equxubu/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/vercel/path0/node_modules/.pnpm/@una-ui+content@0.0.40_@parcel+watcher@2.5.0_@types+node@22.13.1_@unocss+preset-wind@65.4.3_@_qcfpp6lc6lzbwpbe7z7fp2vxle/node_modules/@una-ui/content/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}